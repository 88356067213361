<i18n locale="th" lang="yaml" src="@i18n/service/service.th.yaml">
</i18n>
<i18n locale="th" lang="yaml" >
service_detail.field.drone : "Drone"
service_detail.field.drone.name : "{serialNo} ({name})"
service_detail.field.drone.model : "รุ่น"
service_detail.field.company : "ศูนย์บริการ"
service_detail.field.open_type : "เข้าศูนย์โดย"
service_detail.field.delivery_method : "วิธีนำส่ง"

service_detail.field.return_type : "ส่งคืน"
service_detail.field.return_delivery_method : "วิธีคืน"
service_detail.field.return_date : "วันที่ออกจากศูนย์"

service_detail.field.status.service : "สถานะใบงาน"
service_detail.field.status.repair : "การซ่อม"
service_detail.field.status.payment : "การชำระเงิน"
service_detail.field.status.claim : "เคลม"

service_detail.field.claim_request : "เคลม"
service_detail.field.claim_request.no_claim : "-"
service_detail.field.referrer.company : "ส่งจากศูนย์ {company}"
service_detail.field.referrer.service : "ใบงานเลขที่"
service_detail.field.refer.company : "ส่งไปที่ศูนย์ {company}"
service_detail.field.refer.service : "ใบงานเลขที่"
</i18n>

<template>
	<div v-if="isServiceValid" class="service-detail-container">
		<div class="service-col">
			<label>{{$t('service.field.service_no')}}</label>
			<div class="service-val">
				<div class="service-val-row service-field-service_no">
					{{service.service_no}}
				</div>
				<div class="service-val-row">
					(
						{{$t('service_detail.field.company')}}
						{{service.company.name}}
					)
				</div>
			</div>
		</div>
		<div class="service-col">
			<label>{{$t('service_detail.field.drone')}}</label>
			<div class="service-val">
				<div class="service-val-row">
					<my-router-link name="drone/view" :param-value="droneData.drone.serialNo" param-name="serial_no">
						{{$t('service_detail.field.drone.name',{
								name: droneData.drone.name ,
								serialNo: droneData.drone.serialNo,
							})
						}}
					</my-router-link>
				</div>
				<div class="service-val-row">
					{{$t('service_detail.field.drone.model')}}
					<my-router-link name="drone_model/view" :param-value="droneData.model.id" auth-action="read" auth-module="droneModel">
					{{droneData.model.name}}
					</my-router-link>
					/
					<my-router-link v-trim name="drone_lot/view" :param-value="droneData.lot.id">
						{{droneData.lot.lotName}}
					</my-router-link>
				</div>
			</div>
		</div>
		<div class="service-col">
			<label>{{$t('service.field.customer')}}</label>
			<div class="service-val">
				<div class="service-val-row">
					{{service.customer_name}}
				</div>
				<div class="service-val-row">
					<PhoneLink :value="service.customer_phone" />
					<span style="margin-left:8px;">{{$t('common.province')}}{{service.customer_province}}</span>
				</div>
			</div>
		</div>
		<div class="service-col">
			<label>{{$t('service.field.open_date')}}</label>
			<div class="service-val">
				{{service.open_date | date}} {{$tenum('time_range',service.open_time_range)}}
			</div>
		</div>
		<div class="service-col">
			<label>{{$t('service.field.technician')}}</label>
			<div class="service-val">
				<div class="service-val-row">
					<span v-if="service.technician_id > 0">
						{{service.technicianData.user.username}}
					</span>
					<span v-else>
						{{$t('common.unspecify')}}
					</span>
				</div>
				<div v-if="service.assistant_id > 0" class="service-val-row">
					{{$t('service.field.assistant')}} : {{service.assistantData.user.username}}
				</div>
			</div>
		</div>
		<div class="service-col">
			<label>{{$t('service.field.open_by')}}</label>
			<div class="service-val">
				{{service.openByData.user.username}}
			</div>
		</div>

		<div class="service-col">
			<label>{{$t('service_detail.field.open_type')}}</label>
			<div class="service-val">
				<div class="service-val-row">
					{{openTypeDisplay}}
				</div>
				<div v-if="showReferrer" class="service-val-row">
					{{$t('service_detail.field.referrer.service')}}
					<my-router-link name="service/view" :param-value="referrer.from.id" new-window>
						{{referrer.from.service_no}}
					</my-router-link>
				</div>
			</div>
		</div>
		<div class="service-col col-span-2">
			<label>{{$t('service_detail.field.delivery_method')}}</label>
			<div class="service-val">
				<DeliveryDetailView
						:type="service.delivery_method"
						:delivery-data="service.deliveryData"/>
			</div>
		</div>


		<template v-if="showReturn">
			<div class="service-divider" />
			<div class="service-col">
				<label>{{$t('service_detail.field.return_type')}}</label>
				<div class="service-val">
					{{returnTypeDisplay}}
					<div v-if="showReferDetail" class="service-val-row">
						{{$t('service_detail.field.refer.service')}}
						<my-router-link name="service/view" :param-value="refer.to.id" new-window>
							{{refer.to.service_no}}
						</my-router-link>
					</div>
				</div>
			</div>
			<div class="service-col">
				<label>{{$t('service_detail.field.return_delivery_method')}}</label>
				<div class="service-val">
					<DeliveryDetailView
						:type="returnDetail.delivery_method"
						:delivery-data="returnDetail.deliveryData"
						:is-send-out="true"/>
				</div>
			</div>
			<div class="service-col col-span-2-tablet">
				<label>{{$t('service_detail.field.return_date')}}</label>
				<div class="service-val">
					{{returnDetail.return_date | date}}
				</div>
			</div>
		</template>

		<div class="service-divider" />
		<div class="service-col col-span-2">
			<label>{{$t('service.field.linked_tickets')}}</label>
			<div v-if="$notEmpty(linkTickets)" class="service-val">
				<div v-for="linkTicket in linkTickets" :key="linkTicket.ticket_id" class="service-val-row service-ticket">
					<my-router-link name="ticket/view" :param-value="linkTicket.ticket_id" new-window>
						{{linkTicket.ticketData.ticket_no}}
					</my-router-link>

					<span :title="linkTicket.ticketData.topic">
						{{linkTicket.ticketData.topic}}
					</span>
				</div>
			</div>
			<div v-else class="service-val">
				-
			</div>
		</div>

		<div class="service-col">
			<label>{{$t('service_detail.field.claim_request')}}</label>
			<div class="service-val">
				<span v-if="showClaim" class="service-field-claim_no">
					{{otherDetails.claim_request.claim_no}}
				</span>
				<span v-else>
					{{$t('service_detail.field.claim_request.no_claim')}}
				</span>
			</div>
		</div>

		<div class="service-divider" />
		<div class="service-col col-span-2">
			<label>{{$t('service.field.status')}}</label>
			<div class="service-val">
				<div class="service-val-row service-field-status">
					<label>{{$t('service_detail.field.status.service')}}</label> :
					<StatusDot
						:status="$tenum('service.status',service.status)"
						:description="$t('enum.service.status.' + service.status + '.description')"
						:reason="service.status_reason"
						:icon-mode="serviceStatusDotMode" />
				</div>
				<div v-if="$notEmpty(repairStatus)" class="service-val-row service-field-status">
					<label>{{$t('service_detail.field.status.repair')}}</label> :
					<StatusDot
						:status="$tenum('service.repair.result',repairStatus.result)"
						:reason="repairStatus.reason"
						:icon-mode="repairStatusDotMode" />
				</div>
				<div v-if="$notEmpty(paymentStatus)" class="service-val-row service-field-status">
					<label>{{$t('service_detail.field.status.payment')}}</label> :
					<StatusDot
						:status="$tenum('service.payment.status',paymentStatus.status)"
						:icon-mode="paymentStatusDotMode" />
				</div>
				<div v-if="$notEmpty(claimStatus)" class="service-val-row service-field-status">
					<label>{{$t('service_detail.field.status.claim')}}</label> :
					<StatusDot
						:status="$tenum('claim.status',claimStatus)"
						:description="$tenum('claim.status',claimStatus + '.description')"
						:icon-mode="claimStatusDotMode" />
				</div>

			</div>
		</div>
		<div class="service-col">
			<label>{{$t('service.field.updated_by')}}</label>
			<div class="service-val">
				<div class="service-val-row">
					<my-router-link name="user/view" :param-value="service.updated_by_id">
						<ImageName :name="service.updatedByData.user.username" :src="service.updatedByData.user.image_url">
							<a-icon type="user" />
						</ImageName>
					</my-router-link>
				</div>
				<div class="service-val-row">
					({{$dayjs(service.updated_datetime).format('LLL')}})
				</div>
			</div>
		</div>


	</div>
</template>

<script>
import ServiceObjectMixin from "@mixins/service/ServiceObjectMixin.vue"
import PhoneLink from "@components/common/PhoneLink.vue"
import DeliveryDetailView from "@components/common/DeliveryDetailView.vue"
import ImageName from "@components/common/ImageName.vue"
import StatusDot from "@components/common/StatusDot.vue"
import {lookupStatusDotMode} from "@/src/utils/serviceUtil"

export default {
	components : {
		PhoneLink ,DeliveryDetailView, ImageName,
		StatusDot ,
	} ,
	mixins : [ServiceObjectMixin] ,
	props : {
		service : {
			type : null ,
			default : () => [] ,
		} ,
		otherDetails : {
			type : null,
			default : () => [] ,
		},
		linkTickets : {
			type : null,
			default : () => [] ,
		}
	} ,
	computed : {
		refer() {
			return this.$notEmpty(this.otherDetails.refer) ? this.otherDetails.refer : {}
		} ,
		referrer() {
			return this.$notEmpty(this.otherDetails.referrer) ? this.otherDetails.referrer : {}
		} ,
		returnDetail() {
			return this.$notEmpty(this.otherDetails.return) ? this.otherDetails.return : {}
		},
		paymentStatus() {
			return this.$notEmpty(this.otherDetails.payment_status) ? this.otherDetails.payment_status : null
		} ,
		claimStatus() {
			return 'claim_request' in this.otherDetails ? this.otherDetails.claim_request.status : null
		} ,
		repairStatus() {
			return this.$notEmpty(this.otherDetails.repair_status) ? this.otherDetails.repair_status : null
		} ,
		serviceStatusDotMode() {
			return lookupStatusDotMode('service',this.service.status)
		} ,
		repairStatusDotMode() {
			return lookupStatusDotMode('repair',this.repairStatus.result)
		} ,
		paymentStatusDotMode() {
			return lookupStatusDotMode('payment',this.paymentStatus.status)
		} ,
		claimStatusDotMode() {
			return lookupStatusDotMode('claim',this.claimStatus)
		} ,
		showReturn() {
			return this.isServiceValid &&
				(this.service.status == 'wait_delivery' || this.service.status == 'close') &&
				this.returnDetail && this.returnDetail.id > 0
		} ,
		showRefer() {
			return this.showReturn && this.refer && this.refer.id > 0
		} ,
		showReferDetail() {
			return this.showRefer && this.refer.to.id && this.refer.to.id > 0
		} ,
		showReferrer() {
			return this.isServiceValid && this.service.open_type == 'from_service' && this.referrer.id && this.referrer.id > 0
		} ,
		showClaim() {
			return 'claim_request' in this.otherDetails && this.$notEmpty(this.otherDetails.claim_request.claim_no)
		} ,
		openTypeDisplay() {
			if (this.showReferrer && this.$notEmpty(this.referrer.from.company_name)) {
				return this.$t('service_detail.field.referrer.company',{ company: this.referrer.from.company_name })
			} else {
				return this.$tenum('service.open_type',this.service.open_type)
			}
		} ,
		returnTypeDisplay() {
			if (this.showRefer && this.$notEmpty(this.refer.to.company_name)) {
				return this.$t('service_detail.field.refer.company',{ company: this.refer.to.company_name })
			} else {
				return this.$tenum('service.return.type',this.returnDetail.return_type)
			}
		}
	}
}
</script>

<style lang="less" scoped>
.service-detail-container {
	position: relative;
	display : flex;
	flex-wrap: wrap;
	background-color : @white;
	margin-bottom : 24px;

	border-radius: @border-radius-base;
	box-shadow : @shadow-1-right;
}
.service-divider {
	flex-basis: 100%;
	height : 2px;
	background-color : @primary-1;
}
.visible-tablet {
	display : none !important;
	@media (min-width : @screen-tablet-small) and (max-width : @screen-tablet - 1) {
		display : block !important;
	}
}

.service-col {
	flex-basis: 33%;
	display : flex;
	flex-wrap: nowrap;
	min-width: 0;
	border-bottom : 1px solid @gray-4;
	> label {
		font-family: @font-family-title;
		color : @primary-color;
		min-width : 135px;
		flex-basis : 135px;
		background-color : @gray-3;
		padding : 4px 8px;
	}
	> .service-val {
		flex-grow: 1;
		padding : 4px 8px;
		min-width: 0;
		.service-val-row {
			margin-bottom : 2px;
			&:last-child {
				margin-bottom : 0;
			}
		}
	}

	&.col-span-2 {
		flex-basis: 66%;
	}
	@media(max-width : @screen-tablet-small - 1) {
		flex-basis: 100%;
		&.col-span-2 {
			flex-basis: 100%;
		}
	}
	@media (min-width : @screen-tablet-small) and (max-width : @screen-tablet - 1) {
		flex-basis: 50%;
		&.col-span-2 {
			flex-basis: 50%;
		}
		&.col-span-2-tablet {
			flex-basis: 100%;
		}
	}
}
.service-field-service_no ,
.service-field-claim_no , {
	font-family: @font-family-title;
	color : @primary-color;
	font-size : 16px;
}
.service-val-row.service-field-status {
	margin-bottom : 6px !important;
	label {
		font-family: @font-family-title;
		color : @primary-color;
		display : inline-block;
		width : 75px;
	}
}
.referrer-field-service {
	margin-left : 8px;
}
.service-ticket {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
</style>
