<i18n locale="th" lang="yaml" >
service.warning.close : "กรุณาปิดสถานะใบซ่อม"
service.warning.close.description : "เนื่องจากใบงานนี้มีการส่งต่อไปยังศูนย์บริการอื่น กรุณาปิดสถานะใบซ่อมเมื่อได้ทำการส่งเรียบร้อยแล้ว เพื่อศูนย์ปลายทางจะได้อ้างอิงใบงานนี้ในการรับมอบ Drone ได้"
service.warning.payment : "ลูกค้าชำระเงินไม่ครบ"
service.warning.payment.description : "ใบซ่อมนี้ยังได้รับเงินจากลูกค้าไม่ครบ โปรดตรวจสอบ"
</i18n>

<template>
	<div v-if="showWarning" class="service-warning-container">
		<div v-if="showCloseWarning" class="service-warning-detail">
			<label>
				<a-icon type="file-done" />
				{{$t('service.warning.close')}}
			</label>
			<div class="description">
				{{$t('service.warning.close.description')}}
			</div>
		</div>
		<div v-if="showPaymentWarning" class="service-warning-detail">
			<label>
				<a-icon type="dollar" />
				{{$t('service.warning.payment')}}
			</label>
			<div class="description">
				{{$t('service.warning.payment.description')}}
			</div>
		</div>
	</div>
</template>

<script>
import ServiceObjectMixin from "@mixins/service/ServiceObjectMixin.vue"
import _get from "lodash/get"
export default {
	mixins : [ServiceObjectMixin] ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
		otherDetails : {
			type : null,
			default : () => [] ,
		},
	} ,
	computed : {
		showCloseWarning() {
			if (this.service.status == 'wait_delivery') {
				const returnType = _get(this.otherDetails,'return.return_type')
				if (returnType == 'refer') {
					return true
				}
			}
			return false
		} ,
		showPaymentWarning() {
			if (this.service.status == 'close') {
				const paymentStatus = _get(this.otherDetails,'payment_status.status')
				if (paymentStatus != 'complete')
					return true
			}
			return false
		} ,
		showWarning() {
			return this.canUpdate && (this.showCloseWarning || this.showPaymentWarning)
		} ,
	}
}
</script>

<style lang="less" scoped>
.service-warning-container {
	border : 1px solid @yellow-8;
	border-radius : @border-radius-base;
	background-color : @yellow-1;
	max-width : 800px;
	margin : 0 auto 24px;
	padding : 16px 36px;

	.service-warning-detail {
		margin-bottom : 12px;
		&:last-child {
			margin-bottom : 0;
		}

		label {
			display : block;
			color : @yellow-8;
			font-size : 1.1em;
			font-family: @font-family-title;
			margin-bottom : 4px;
		}
		.description {
			font-size : 0.95em;
		}
	}

	.mobile & {
		margin-top : 12px;
	}
}
</style>
